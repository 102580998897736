export const avatars = [
  'https://img.szgchw.cn/834327227170828',
  'https://img.szgchw.cn/834327219830800',
  'https://img.szgchw.cn/834327220355075',
  'https://img.szgchw.cn/834327215112196',
  'https://img.szgchw.cn/834327216160769',
  'https://img.szgchw.cn/834327218257922',
  'https://img.szgchw.cn/834327215636484',
  'https://img.szgchw.cn/834327218257926',
  'https://img.szgchw.cn/834327217209355',
  'https://img.szgchw.cn/834327221403655',
  'https://img.szgchw.cn/834327221927940',
  'https://img.szgchw.cn/834327215636482',
  'https://img.szgchw.cn/834327218782216',
  'https://img.szgchw.cn/834327219830796',
  'https://img.szgchw.cn/834327218782223',
  'https://img.szgchw.cn/834327216685067',
  'https://img.szgchw.cn/834327224025088',
  'https://img.szgchw.cn/834327217209345',
  'https://img.szgchw.cn/834327225597966',
  'https://img.szgchw.cn/834327227170821',
  'https://img.szgchw.cn/834327227170818',
  'https://img.szgchw.cn/834327222452233',
  'https://img.szgchw.cn/834327218782218',
  'https://img.szgchw.cn/834327222452229',
  'https://img.szgchw.cn/834327215112206',
  'https://img.szgchw.cn/834327217733642',
  'https://img.szgchw.cn/834327215636483',
  'https://img.szgchw.cn/834327216160770',
  'https://img.szgchw.cn/834327217733634',
  'https://img.szgchw.cn/834327223500811',
  'https://img.szgchw.cn/834327214063618',
  'https://img.szgchw.cn/834327217733633',
  'https://img.szgchw.cn/834327221403660',
  'https://img.szgchw.cn/834327224025096',
  'https://img.szgchw.cn/834327216160776',
  'https://img.szgchw.cn/834327220355081',
  'https://img.szgchw.cn/834327218257920',
  'https://img.szgchw.cn/834327225597958',
  'https://img.szgchw.cn/834327226646537',
  'https://img.szgchw.cn/834327224549384',
  'https://img.szgchw.cn/834327218782209',
  'https://img.szgchw.cn/834327220879360',
  'https://img.szgchw.cn/834327222976522',
  'https://img.szgchw.cn/834327224549387',
  'https://img.szgchw.cn/834327219830795',
  'https://img.szgchw.cn/834327224025093',
  'https://img.szgchw.cn/834327224025095',
  'https://img.szgchw.cn/834327225597954',
  'https://img.szgchw.cn/834327220355072',
  'https://img.szgchw.cn/834327214587906',
  'https://img.szgchw.cn/834327216685061',
  'https://img.szgchw.cn/834327216160774',
  'https://img.szgchw.cn/834327216685058',
  'https://img.szgchw.cn/834327220879363',
  'https://img.szgchw.cn/834327218257928',
  'https://img.szgchw.cn/834327219306499',
  'https://img.szgchw.cn/834327218257925',
  'https://img.szgchw.cn/834327226122242',
  'https://img.szgchw.cn/834327217209352',
  'https://img.szgchw.cn/834327226646542',
  'https://img.szgchw.cn/834327213539329',
  'https://img.szgchw.cn/834327223500801',
  'https://img.szgchw.cn/834327224025089',
  'https://img.szgchw.cn/834327219830801',
  'https://img.szgchw.cn/834327223500803',
  'https://img.szgchw.cn/834327225597960',
  'https://img.szgchw.cn/834327214587911',
  'https://img.szgchw.cn/834327219306506',
  'https://img.szgchw.cn/834327221927945',
  'https://img.szgchw.cn/834327227170817',
  'https://img.szgchw.cn/834327215112201',
  'https://img.szgchw.cn/834327219830789',
  'https://img.szgchw.cn/834327217733632',
  'https://img.szgchw.cn/834327216685063',
  'https://img.szgchw.cn/834327223500814',
  'https://img.szgchw.cn/834327226122250',
  'https://img.szgchw.cn/834327226646530',
  'https://img.szgchw.cn/834327226646533',
  'https://img.szgchw.cn/834327226122252',
  'https://img.szgchw.cn/834327221927943',
  'https://img.szgchw.cn/834327218257932',
  'https://img.szgchw.cn/834327218257931',
  'https://img.szgchw.cn/834327224025097',
  'https://img.szgchw.cn/834327215636490',
  'https://img.szgchw.cn/834327220879368',
  'https://img.szgchw.cn/834327225597965',
  'https://img.szgchw.cn/834327220355074',
  'https://img.szgchw.cn/834327216160775',
  'https://img.szgchw.cn/834327219830786',
  'https://img.szgchw.cn/834327221403662',
  'https://img.szgchw.cn/834327219306503',
  'https://img.szgchw.cn/834327220355078',
  'https://img.szgchw.cn/834327222452232',
  'https://img.szgchw.cn/834327214587910',
  'https://img.szgchw.cn/834327218257923',
  'https://img.szgchw.cn/834327220355089',
  'https://img.szgchw.cn/834327225597957',
  'https://img.szgchw.cn/834327223500813',
  'https://img.szgchw.cn/834327223500816',
  'https://img.szgchw.cn/834327222452231'
]

export const familyNames = [
  '赵',
  '李',
  '王',
  '刘',
  '孙',
  '郑',
  '任',
  '王',
  '钱',
  '李',
  '傅',
  '周',
  '陈',
  '五',
  '松',
  '张',
  '黄',
  '史',
  '沙',
  '孙',
  '朱',
  '唐',
  '莎',
  '梦',
  '清',
  '绝'
]
export const phoneNumbersStart = [
  '155',
  '138',
  '130',
  '188',
  '180',
  '157',
  '130',
  '150',
  '151',
  '139',
  '155',
  '138',
  '130',
  '188'
]
export const goodsName = [
  '无人机',
  '智能手表',
  '法国葡萄酒',
  '坚果礼盒',
  '蓝牙耳机',
  '护肤套组',
  '空气炸锅',
  '足浴盆',
  '茅台酒',
  '锅具套装',
  '扫地机'
]

export const nickNames = [
  '岁月静好',
  '听风是雨',
  '浮生若梦',
  '半壶纱',
  '静水流深',
  '岁月如歌',
  '一叶知秋',
  '老酒一壶',
  '黄昏独酌',
  '墨染青衣',
  '落笔画秋声',
  '浅笑嫣然',
  '青灯古佛',
  '寒烟翠',
  '倚剑听风',
  '忆江南',
  '半盏屠苏',
  '老树昏鸦',
  '风中追风',
  '云淡风轻',
  '岁月神偷',
  '独钓寒江雪',
  '一书生',
  '茶暖酒醇',
  '听雨轩',
  '晚风拂柳',
  '落花人独立',
  '梦回唐朝',
  '青山遮不住',
  '千山暮雪'
]

export default {
  avatars,
  familyNames,
  phoneNumbersStart,
  goodsName,
  nickNames
}
