import axios from 'axios'
import _ from 'lodash'
import utils from 'base/utils'
import xEnum from 'base/utils/enum'
import { checkJson } from './index'
// import store from '@/store'

/*
(function() {
    var scrs = document.getElementsByTagName("script");
    var loadUrl=scrs[scrs.length-1].getAttribute("src");
    function getQueryVariable(query,variable){
        if (query.startsWith("http")){
            query=new URL(query).search.substring(1);
        }else {
            query=query.substring(query.indexOf("?")+1);
        }
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
        }
        return null;
    }
    var baidu=getQueryVariable(loadUrl,"baiduId"),umeng=getQueryVariable(loadUrl,"umengId");
    rpInit({umengId: umeng, baiduId: baidu});
})();
*/

const flag = false

// 全渠道聚合基线
// https://tongji.baidu.com/sc-web/10000171383/home/site/getjs?siteId=16024277
// eslint-disable-next-line no-unused-vars
const allChannelSiteId = 'fec939cfe4634b752fcc332842caff4a'
export function rpInit(data) {
  const umeng = data?.umengId,
    baidu = data?.baiduId,
    pId = data?.pId
  if (!window._czc || window._czc instanceof Array) {
    window._czc = []
    if (umeng != null && umeng.trim().length > 0 && umeng !== 'null') {
      //1278599417
      const hm = document.createElement('script')
      hm.src =
        ('https:' == document.location.protocol ? ' https://' : ' http://') +
        'v1.cnzz.com/z_stat.php?id=' +
        umeng +
        '&web_id=' +
        umeng
      const s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(hm, s)
    }
  }
  if (!window._hmt || window._hmt instanceof Array) {
    window._hmt = []
    if (baidu != null && baidu.trim().length > 0 && baidu !== 'null') {
      //da39f118d2bb15170eb5abf946b81a63
      const hm1 = document.createElement('script')
      hm1.src = 'https://hm.baidu.com/hm.js?' + baidu
      const s1 = document.getElementsByTagName('script')[0]
      s1.parentNode.insertBefore(hm1, s1)

      // 百度统计设置
      // window._hmt.push(['_setAccount', '0123456789ABCDEF0123456789ABCDEF'])
      window._hmt.push(['_setCustomVar', 1, 'pId', pId, 1])
    }
  }
}
export function rpPush(param) {
  if (location.hostname == 'localhost') {
    console.log(param)
    return
  }
  if (window._czc) {
    window._czc.push(param)
  }
  if (window._hmt && !(window._hmt instanceof Array)) {
    window._hmt.push(param)
  }
}

export function trackPageview(url, p) {
  rpPush(['_trackPageview', url, p || '/'])
}

export function trackEvent(data) {
  try {
    if (data && data.category) {
      rpPush(['_trackEvent', data.category, data.action || '无', data.opt_label, data.opt_value])
    }
  } catch (e) {
    console.info('友盟增加事件错误')
  }
}

export function setCustomVar(data) {
  if (data && data.index && data.name && data.value) {
    rpPush(['_setCustomVar', data.index, data.name, data.value, data.opt_scope])
  }
}

export function trackUserEvent(params) {
  if (!flag) return
  const appType = xEnum.ENUM_APP_TYPE.H5.getValue()
  // console.log('process.env.VUE_APP_BRAND', process.env.VUE_APP_ENABLE_MONITOR, Number(process.env.VUE_APP_ENABLE_MONITOR))
  if (!Number(process.env.VUE_APP_ENABLE_MONITOR)) {
    return
  }

  /**
   * saas版中传给后端用于做数据上报来源的 source 不再使用打包命令中 --brand 所生成的品牌名来赋枚举值。
   * 后期理想化的是前端多品牌架构是需要去掉的（即所有可配置项都由后台来配置），打包命令也不需要加 --brand 等额外参数。
   * 目前这里数据上报要用到的来源参数 source 暂时在环境配置文件中加一个全局变量枚举值来由于做上报数据来源区分。
   */
  const currentSourceEnum = Number(process.env.VUE_APP_SOURCE_ENUM)
  const sourceXEnum = _.find(xEnum.ENUM_DATA_REPORT_SOURCES, (item) => {
    return item.value === currentSourceEnum
  })
  const source = sourceXEnum.value

  const { eventType, eventInfo } = params
  let buryPointConfig = {
    exposeDuration: 300,
    exposeMinAreaRate: 0.8,
    pushInterval: 2000,
    queueSize: 20,
    eventTypes: [Number(xEnum.ENUM_RP_EVENT_TYPE.ROUTE_TO_PAGE.value)]
  }
  if (checkJson(window.localStorage.getItem('buryPointConfig'))) {
    buryPointConfig = JSON.parse(window.localStorage.getItem('buryPointConfig'))
  }
  if (!(buryPointConfig?.eventTypes ?? []).includes(Number(eventType))) {
    return
  }
  if (eventInfo?.webUrl) {
    eventInfo.webUrl = `(${eventInfo.webUrl})`
  }
  // 用户信息
  const userInfo = {
    tel: localStorage.getItem('phone') ?? '',
    openId: localStorage.getItem('openid') ?? ''
  }
  const pId = window.localStorage.getItem('pId')
  const nonce = utils.randomString(8)
  const timestamp = new Date().getTime()
  //创建用户唯一会话id
  let userSessionId = ''
  if (window.sessionStorage.getItem('userSessionId')) {
    userSessionId = window.sessionStorage.getItem('userSessionId')
  } else {
    userSessionId = utils.getSessionId()
    window.sessionStorage.setItem('userSessionId', userSessionId)
  }
  const paramsObj = _.omitBy(
    {
      // 渠道号
      pId: pId,
      // 商品ID
      goodsId: eventInfo?.goodsId,
      // 商品组类型
      goodsGroupId: eventInfo?.goodsGroupId,
      // 页面url
      // nonce防篡改
      nonce,
      // goodsIndex排名索引
      // score排名权重
      // userId
      // goodsPrice商品价格
      // eventType
      appType,
      source,
      eventType,
      timestamp,
      ...eventInfo,
      ...userInfo,
      userSessionId
    },
    (param) => param === null || param === undefined
  )
  const signature = utils.genSignature({ pId, timestamp, eventType })
  axios.defaults.headers['Accept-Locale'] = signature
  return new Promise((resolve, reject) => {
    axios
      .get(`https://bigdata.szgchw.cn/collect/odsCollect`, { params: paramsObj })
      .then((res) => {
        resolve(res)
        // console.log(res)
      })
      .catch((err) => {
        reject(err)
        // console.log(err)
      })
  })
}

// 监听浏览器关闭事件钩子函数 defect: cb函数api异步调用之前，浏览器线程 destroyed
export function listenUnloadEvents(cb) {
  window.addEventListener('onbeforeunload', () => {
    cb()
    return () => {
      window.removeEventListener('onbeforeunload', cb())
    }
  })
}

export function webfunnyTrack(params) {
  if (!flag) return
  const appType = xEnum.ENUM_APP_TYPE.H5.getValue()

  if (!Number(process.env.VUE_APP_ENABLE_WEBFUNNY)) {
    return
  }

  /**
   * saas版中传给后端用于做数据上报来源的 source 不再使用打包命令中 --brand 所生成的品牌名来赋枚举值。
   * 后期理想化的是前端多品牌架构是需要去掉的（即所有可配置项都由后台来配置），打包命令也不需要加 --brand 等额外参数。
   * 目前这里数据上报要用到的来源参数 source 暂时在环境配置文件中加一个全局变量枚举值来由于做上报数据来源区分。
   */
  let source = null
  const currentSourceEnum = Number(process.env.VUE_APP_SOURCE_ENUM)
  const sourceXEnum = _.find(xEnum.ENUM_DATA_REPORT_SOURCES, (item) => {
    return item.value === currentSourceEnum
  })
  source = sourceXEnum.value

  const { pointId, eventType, eventInfo } = params

  if (eventInfo?.webUrl) {
    const reg = /[(|)]/g
    if (eventInfo.webUrl.match(reg)) {
      eventInfo.webUrl = eventInfo.webUrl.replace(reg, '')
    }
  }
  // eslint-disable-next-line no-undef
  _webfunnyEvent[pointId]?.trackEvent({ ...eventInfo, appType, source, eventType })
}

// 页面性能采集
export function getPagePerformance() {
  const timingInfo = JSON.parse(JSON.stringify(window.performance.timing))
  console.log(timingInfo)
  // DNS解析，DNS查询耗时
  const dnsStamp = timingInfo?.domainLookupEnd - timingInfo?.domainLookupStart
  // TCP连接耗时
  const connectStamp = timingInfo.connectEnd - timingInfo?.connectStart
  // 获得首字节耗费时间，也叫TTFB
  const ttfb = timingInfo.responseStart - timingInfo?.navigationStart
  // domReady时间(与DomContentLoad事件对应)
  const domReady = timingInfo.domContentLoadedEventStart - timingInfo.navigationStart
  // DOM资源下载
  const domDownload = timingInfo.responseEnd - timingInfo.responseStart
  // 准备新页面时间耗时
  const newPage = timingInfo.fetchStart - timingInfo.navigationStart
  // 重定向耗时
  const redirect = timingInfo.redirectEnd - timingInfo.redirectStart
  // Appcache 耗时
  const appCache = timingInfo.domainLookupStart - timingInfo.fetchStart
  // unload 当前文档耗时
  const upload = timingInfo.unloadEventEnd - timingInfo.unloadEventStart
  // request请求耗时
  const request = timingInfo.responseEnd - timingInfo.requestStart
  // 请求完毕至DOM加载
  const domInteractive = timingInfo.domInteractive - timingInfo.responseEnd
  // 解释dom树耗时
  const domComplete = timingInfo.domComplete - timingInfo.domInteractive
  // 从开始至load总耗时
  const startToLoadEnd = timingInfo.loadEventEnd - timingInfo.navigationStart
  // *: 白屏时间
  const blankView = timingInfo.responseStart - timingInfo.fetchStart
  // *: 首屏时间
  const firstView = timingInfo.domComplete - timingInfo.fetchStart
  return {
    dnsStamp,
    connectStamp,
    ttfb,
    domReady,
    domDownload,
    newPage,
    redirect,
    appCache,
    upload,
    request,
    domInteractive,
    domComplete,
    startToLoadEnd,
    blankView,
    firstView
  }
}
