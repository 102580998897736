/* eslint-disable camelcase */
import store from '@/store'
import xEnum from 'base/utils/enum'
import utils from 'base/utils'

export const EventType = {
  Exp: 'EXP',
  Click: 'CLK',
  Other: 'OTHER'
}

const qtFlag = false

function getUserGlobalParams() {
  if (!qtFlag) return
  const currentSourceEnum = Number(process.env.VUE_APP_SOURCE_ENUM)
  const sourceXEnum = _.find(xEnum.ENUM_DATA_REPORT_SOURCES, (item) => {
    return item.value === currentSourceEnum
  })
  //创建用户唯一会话id
  let userSessionId = ''
  if (window.sessionStorage.getItem('userSessionId')) {
    userSessionId = window.sessionStorage.getItem('userSessionId')
  } else {
    userSessionId = utils.getSessionId()
    window.sessionStorage.setItem('userSessionId', userSessionId)
  }
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const paramsObj = {}
  urlParams.forEach((value, key) => {
    paramsObj[key] = value
  })
  const userGlobalParams = {
    project_id: paramsObj.pId || store.state.appState.pId,
    user_id: store.state.user.userId || localStorage.getItem('userId'),
    web_url: window.location.href,
    timestamp: new Date().getTime(),
    point: store.state.user.points.activePoints,
    source: sourceXEnum.value,
    app_type: store.state.appState.appType,
    phone: store.state.user.phone || '',
    app_branch_type: 'A',
    project_name: store.state.appState.projectName,
    user_session_id: userSessionId
  }
  return userGlobalParams
}
// const appKey = process.env.VUE_APP_QUICK_TRACK_APPKEY

export function loadQuickTrackSDK() {
  if (!qtFlag) return // eslint-disable-next-line no-extra-semi
  ;(function (w, d, s, q) {
    w[q] = w[q] || []
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s)
    j.async = true
    j.id = 'beacon-aplus'
    j.src = 'https://o.alicdn.com/QTSDK/quicktracking-sdk/qt_web.umd.js'
    f.parentNode.insertBefore(j, f)
  })(window, document, 'script', 'aplus_queue')

  try {
    const { aplus_queue } = window
    if (!aplus_queue) return

    //集成应用的appKey
    const appKey = {
      dacu: '3hax0vkyr3bxbowm9apqzsup'
    }
    aplus_queue.push({ action: 'aplus.setMetaInfo', arguments: ['appKey', appKey.dacu] })
    //如果是私有云部署还需要在上面那段JS后面紧接着添加日志域名埋点
    //通常私有云日志服务端域名类似于：quickaplus-web-api.xxx.com.cn, 具体域名要找交付同学要
    aplus_queue.push({
      action: 'aplus.setMetaInfo',
      arguments: ['aplus-rhost-v', 'quickaplus-he-api-cn-shanghai.aliyuncs.com']
    })

    /* 如果使用的是单页面应用，例如：React、Vue、Angular等，则需要添加下面的代码 */
    /* 关闭自动PV发送，如果不是单页面应用，请删掉下方代码 */
    aplus_queue.push({
      action: 'aplus.setMetaInfo',
      arguments: ['aplus-waiting', 'MAN']
    })
    // 关闭点击事件自动上报
    aplus_queue.push({
      action: 'aplus.setMetaInfo',
      arguments: ['aplus-autotrack-enabled', false]
    })
    // 关闭pv事件自动上报
    aplus_queue.push({
      action: 'aplus.setMetaInfo',
      arguments: ['aplus-disable-apv', true]
    })

    setUserInfo()
  } catch (error) {
    console.log('error', error)
  }
}

// 设置全局属性
function setUserInfo() {
  if (!qtFlag) return
  const userId = store.state.user.userId || localStorage.getItem('userId')
  try {
    if (!window.aplus_queue) return
    const { aplus_queue } = window
    if (!aplus_queue) return
    const actionList = [
      {
        action: 'aplus.setMetaInfo',
        arguments: ['_hold', 'BLOCK']
      },
      {
        action: 'aplus.setMetaInfo',
        arguments: ['_user_id', userId]
      },
      {
        action: 'aplus.setMetaInfo',
        arguments: ['_hold', 'START']
      }
    ]
    actionList.forEach((item) => {
      aplus_queue.push(item)
    })
  } catch (error) {
    console.log('setUserInfo', error)
  }
}

// 事件日志
export function sendQuickTrackingEvent({ eventName, eventType, params }) {
  if (!qtFlag) return
  const { aplus_queue } = window
  if (!aplus_queue) return

  const userGlobalParams = getUserGlobalParams()
  const regex = /\/vantmall(.*)/
  const result = regex.exec(window.location.pathname)
  let eventPagePath = ''
  if (result) {
    eventPagePath = result[1].includes('goodsProfile') ? '/goodsProfile' : result[1]
  }
  setGlobalMetaInfo({
    event_page_path: eventPagePath,
    event_device: navigator.userAgent,
    ...userGlobalParams
  })

  aplus_queue.push({
    action: 'aplus.record',
    arguments: [
      eventName,
      eventType,
      {
        ...params,
        page_name: qtPageMap?.get(eventPagePath) || 'unknown'
      }
    ]
  })
  // console.log('触发事件埋点', params)
}

function setGlobalMetaInfo(params) {
  if (!qtFlag) return
  const aplus_queue = window.aplus_queue
  if (!aplus_queue) return
  aplus_queue.push({
    action: 'aplus.appendMetaInfo', //追加全局属性
    arguments: ['globalproperty', params]
  })
}

// 页面日志
export function sendPageTrackEvent(pagePath) {
  if (!qtFlag) return
  const aplus_queue = window.aplus_queue
  if (!aplus_queue) return
  const purePath = utils.extractPathAndId(pagePath)
  const pageName = qtPageMap?.get(purePath) || 'unknown'
  const userGlobalParams = getUserGlobalParams()
  const globalParams = {
    event_page_path: purePath,
    ...userGlobalParams
  }
  setGlobalMetaInfo(globalParams)
  const pvParams = {
    action: 'aplus.sendPV',
    arguments: [
      {
        is_auto: false
      },
      {
        page_name: pageName
      }
    ]
  }
  if (store.state.user.userId) {
    aplus_queue.push(pvParams)
  } else {
    store.state.appState.cacheQtEvents.push({
      globalParams,
      pvParams
    })
  }
}

const qtPageMap = new Map([
  ['/complain', 'dacuecmc_complaint_page'],
  ['/leftFirst', 'dacuecmc_crazy_rank_page'],
  ['/activity/brand', 'dacuecmc_event_page'],
  ['/activity/returnScore', 'dacuecmc_full_return_page'],
  ['/goodsProfile', 'dacuecmc_goods_detail_page'],
  ['/home', 'dacuecmc_hp_page'],
  ['/activity/timeLimit', 'dacuecmc_limited_time_flash_sale_page'],
  ['/activity/timeLimit', 'dacuecmc_limited_time_flash_sale_page'],
  ['/express', 'dacuecmc_logistics_page'],
  ['/myorder3', 'dacuecmc_myorder_page'],
  ['/customService1', 'dacuecmc_ocsp_page'],
  ['/payindex', 'dacuecmc_orderconfirm_page'],
  ['/ordersuccess', 'dacuecmc_payment_suc_page'],
  ['/mine', 'dacuecmc_personal_page'],
  ['/userInfo', 'dacuecmc_personal_page'],
  ['/primary1', 'dacuecmc_points_center_page'],
  ['/protocol', 'dacuecmc_privacy_policy_page'],
  ['/goodsSearch', 'dacuecmc_search_page'],
  ['/grantPhone', 'dacuecmc_authorization_page'],
  ['/centerPageA', 'dacuecmc_push_flow_page'],
  ['/centerPageB', 'dacuecmc_push_flow_page'],
  ['/fillRefundNumber', 'dacuecmc_fill_refund_number_page'],
  ['/serviceOrdersDetail', 'dacuecmc_service_orders_detail_page'],
  ['/cooperation', 'dacuecmc_cooperation_page'],
  ['/comment', 'dacuecmc_comment_page']
])

export const sendCacheQtEvents = (userId) => {
  if (!qtFlag) return
  const { aplus_queue } = window
  if (!aplus_queue) return
  store.state.appState.cacheQtEvents.forEach((event) => {
    const { globalParams, pvParams } = event
    setGlobalMetaInfo({ ...globalParams, user_id: userId })
    aplus_queue.push({
      action: 'aplus.setMetaInfo',
      arguments: ['_user_id', userId]
    })
    aplus_queue.push(pvParams)
  })
  store.state.appState.cacheQtEvents = []
}

export default {
  loadQuickTrackSDK,
  sendQuickTrackingEvent,
  sendPageTrackEvent,
  sendCacheQtEvents,
  qtPageMap
}
